import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  Chip,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Fade,
  InputAdornment,
  Grid,
  SelectChangeEvent
} from '@mui/material';
import {
  Edit as EditIcon,
  Refresh as RefreshIcon,
  Send as SendIcon,
  People as PeopleIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Clear as ClearIcon
} from '@mui/icons-material';

interface User {
  id: string;
  name: string;
  email: string;
  platform: string;
  user_id: string;
  last_used: string;
}

interface NotificationData {
  title: string;
  body: string;
  type: 'info' | 'success' | 'warning' | 'error';
  data: {
    extraInfo: string;
  };
}

interface SortConfig {
  key: keyof User;
  direction: 'asc' | 'desc';
}

interface ApiResponse {
  success: boolean;
  tokens: User[];
}

const Notification: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [platformFilter, setPlatformFilter] = useState<string>('all');
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'name',
    direction: 'asc'
  });

  const [notificationData, setNotificationData] = useState<NotificationData>({
    title: '',
    body: '',
    type: 'info',
    data: { extraInfo: '' }
  });

  const fetchUsers = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch('https://api.skyla.game/firebase/allToken');
      const data: ApiResponse = await response.json();
      if (data.success) {
        setUsers(data.tokens);
        setFilteredUsers(data.tokens);
      }
    } catch (err) {
      setError('Failed to fetch users');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    let result = [...users];
    
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(user => 
        user.name.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
      );
    }

    if (platformFilter !== 'all') {
      result = result.filter(user => user.platform === platformFilter);
    }

    result.sort((a, b) => {
      let comparison = 0;
      if (sortConfig.key === 'last_used') {
        comparison = new Date(a[sortConfig.key]).getTime() - new Date(b[sortConfig.key]).getTime();
      } else {
        comparison = String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]));
      }
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    });

    setFilteredUsers(result);
  }, [users, searchQuery, platformFilter, sortConfig]);

  const handleSort = (key: keyof User): void => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const handleSendNotification = async (isBulk: boolean = false): Promise<void> => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const endpoint = isBulk 
        ? 'https://api.skyla.game/firebase/send-bulk'
        : 'https://api.skyla.game/firebase/send-to-user';

      const payload = {
        ...(selectedUser && !isBulk && { userId: selectedUser.user_id }),
        ...notificationData
      };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        setSuccess(`Notification sent successfully to ${isBulk ? 'all users' : selectedUser?.name}`);
        setShowModal(false);
        setNotificationData({
          title: '',
          body: '',
          type: 'info',
          data: { extraInfo: '' }
        });
      } else {
        throw new Error('Failed to send notification');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
    setLoading(false);
  };

  const handlePlatformChange = (event: SelectChangeEvent): void => {
    setPlatformFilter(event.target.value);
  };

  const handleNotificationTypeChange = (event: SelectChangeEvent): void => {
    setNotificationData({
      ...notificationData,
      type: event.target.value as NotificationData['type']
    });
  };

  const platforms: string[] = ['all', ...Array.from(new Set(users.map(user => user.platform)))];

  return (
    <div className="ml-0 lg:ml-[256px] p-2">
    <Box sx={{ p: 3, maxWidth: '1200px', margin: '0 auto' }}>
      <Fade in timeout={1000}>
        <Card elevation={3}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                Notification Dashboard
              </Typography>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={fetchUsers}
                disabled={loading}
                sx={{ 
                  transition: 'all 0.3s ease',
                  '&:hover': { transform: 'rotate(180deg)' }
                }}
              >
                Refresh Users
              </Button>
            </Box>

            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  placeholder="Search by name or email"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: searchQuery ? (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setSearchQuery('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Platform Filter</InputLabel>
                  <Select
                    value={platformFilter}
                    label="Platform Filter"
                    onChange={handlePlatformChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterIcon />
                      </InputAdornment>
                    }
                  >
                    {platforms.map(platform => (
                      <MenuItem key={platform} value={platform}>
                        {platform.charAt(0).toUpperCase() + platform.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<PeopleIcon />}
                  onClick={() => {
                    setSelectedUser(null);
                    setShowModal(true);
                  }}
                  sx={{ 
                    height: '56px',
                    transition: 'all 0.3s ease',
                    '&:hover': { transform: 'translateY(-2px)' }
                  }}
                >
                  Send Bulk Notification
                </Button>
              </Grid>
            </Grid>

            {error && (
              <Fade in>
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              </Fade>
            )}

            {success && (
              <Fade in>
                <Alert severity="success" sx={{ mb: 2 }}>
                  {success}
                </Alert>
              </Fade>
            )}

            <Fade in timeout={500}>
              <TableContainer 
                component={Paper} 
                elevation={2}
                sx={{ 
                  transition: 'all 0.3s ease',
                  '&:hover': { transform: 'translateY(-2px)' }
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: 'primary.main' }}>
                      <TableCell sx={{ color: 'white' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'name'}
                          direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('name')}
                          sx={{ '&.MuiTableSortLabel-root': { color: 'white' } }}
                        >
                          User
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'email'}
                          direction={sortConfig.key === 'email' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('email')}
                          sx={{ '&.MuiTableSortLabel-root': { color: 'white' } }}
                        >
                          Email
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'platform'}
                          direction={sortConfig.key === 'platform' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('platform')}
                          sx={{ '&.MuiTableSortLabel-root': { color: 'white' } }}
                        >
                          Platform
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'last_used'}
                          direction={sortConfig.key === 'last_used' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('last_used')}
                          sx={{ '&.MuiTableSortLabel-root': { color: 'white' } }}
                        >
                          Last Used
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((user) => (
                      <TableRow 
                        key={user.id} 
                        hover
                        sx={{ 
                          transition: 'all 0.2s ease',
                          '&:hover': { 
                            backgroundColor: 'action.hover',
                            transform: 'scale(1.01)'
                          }
                        }}
                      >
                        <TableCell>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {user.name}
                          </Typography>
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <Chip
                            label={user.platform}
                            color="primary"
                            variant="outlined"
                            size="small"
                            sx={{ 
                              transition: 'all 0.2s ease',
                              '&:hover': { transform: 'scale(1.1)' }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {new Date(user.last_used).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<SendIcon />}
                            onClick={() => {
                              setSelectedUser(user);
                              setShowModal(true);
                            }}
                            sx={{ 
                              transition: 'all 0.2s ease',
                              '&:hover': { transform: 'translateX(2px)' }
                            }}
                          >
                            Send
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Fade>
          </CardContent>
        </Card>
      </Fade>

      <Dialog 
        open={showModal} 
        onClose={() => setShowModal(false)}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Fade}
        transitionDuration={300}
      >
        <DialogTitle>
          {selectedUser ? `Send Notification to ${selectedUser.name}` : 'Send Bulk Notification'}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label="Title"
              fullWidth
              value={notificationData.title}
              onChange={(e) =>
                setNotificationData({ ...notificationData, title: e.target.value })
              }
              variant="outlined"
            />
            <TextField
              label="Message"
              fullWidth
              multiline
              rows={4}
              value={notificationData.body}
              onChange={(e) =>
                setNotificationData({ ...notificationData, body: e.target.value })
              }
              variant="outlined"
            />
            <FormControl fullWidth>
              <InputLabel>Notification Type</InputLabel>
              <Select
                value={notificationData.type}
                label="Notification Type"
                onChange={handleNotificationTypeChange}
              >
                <MenuItem value="info">Info</MenuItem>
                <MenuItem value="success">Success</MenuItem>
                <MenuItem value="warning">Warning</MenuItem>
                <MenuItem value="error">Error</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Additional Information"
              fullWidth
              value={notificationData.data.extraInfo}
              onChange={(e) =>
                setNotificationData({
                  ...notificationData,
                  data: { extraInfo: e.target.value }
                })
              }
              variant="outlined"
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={() => setShowModal(false)}
            color="inherit"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSendNotification(!selectedUser)}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
            sx={{ 
              transition: 'all 0.2s ease',
              '&:hover': { transform: 'translateX(2px)' }
            }}
          >
            {loading ? 'Sending...' : 'Send Notification'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError('');
          setSuccess('');
        }}
        TransitionComponent={Fade}
        sx={{ 
          '& .MuiSnackbarContent-root': {
            transition: 'all 0.3s ease',
            '&:hover': { transform: 'scale(1.02)' }
          }
        }}
      >
        <Alert
          severity={error ? 'error' : 'success'}
          variant="filled"
          sx={{ 
            width: '100%',
            transition: 'all 0.3s ease',
            animation: 'slideIn 0.3s ease-out'
          }}
        >
          {error || success}
        </Alert>
      </Snackbar>

      <style>
        {`
          @keyframes slideIn {
            from {
              transform: translateX(100%);
              opacity: 0;
            }
            to {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes scaleIn {
            from {
              transform: scale(0.95);
              opacity: 0;
            }
            to {
              transform: scale(1);
              opacity: 1;
            }
          }

          html {
            scroll-behavior: smooth;
          }

          * {
            transition: background-color 0.3s ease,
                      transform 0.3s ease,
                      opacity 0.3s ease;
          }
        `}
      </style>

      <Dialog
        open={loading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2,
            animation: 'scaleIn 0.3s ease-out'
          }}
        >
          <CircularProgress size={60} />
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              color: 'primary.main',
              animation: 'fadeIn 0.3s ease-out'
            }}
          >
            Processing...
          </Typography>
        </Box>
      </Dialog>

      {filteredUsers.length === 0 && !loading && (
        <Fade in timeout={500}>
          <Box
            sx={{
              textAlign: 'center',
              p: 4,
              mt: 4,
              bgcolor: 'background.paper',
              borderRadius: 2,
              animation: 'fadeIn 0.5s ease-out'
            }}
          >
            <Typography variant="h6" color="text.secondary">
              No users found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Try adjusting your search or filters
            </Typography>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => {
                setSearchQuery('');
                setPlatformFilter('all');
                setSortConfig({ key: 'name', direction: 'asc' });
              }}
              sx={{
                mt: 2,
                transition: 'all 0.3s ease',
                '&:hover': { transform: 'scale(1.05)' }
              }}
            >
              Reset Filters
            </Button>
          </Box>
        </Fade>
      )}
    </Box>
    </div>
  );
};

export default Notification;