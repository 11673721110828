import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Users from './components/Users';
import Settings from './components/Settings';
import AdminDashboard from './components/AdminDashboard';
import LoginPage from './components/Login';
import Wallet from './components/WalletUpdate';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import House from './components/House'
import History from './components/History';
import Game from './components/Games';
import GamesLog from './components/WinSetting';
import SEO from './components/SeoManager';
import Referal from './components/Refrel';
import Bonus from './components/Bonus';
import NotificationSettings from './components/NotificationSettings';
import WinSettings from './components/WinSetting';
import ClubMemberShip from './components/ClubMembership';
import SiteConfig from './components/SiteConfig';
import Banner from './components/Banner';
import BankDetails from './components/BankDetails';
import { Buffer } from 'buffer';
import SportsBetting from './components/SportsBetting';
import RedeemCode from './components/RedeemCode';
import ExchangeRate from './components/ExchangeRate';
import Notification from './components/notification';
import SwapHistory from './components/SwapHistory';

function App() {
  window.Buffer = Buffer; 
  const token = localStorage.getItem('token');

  const ProtectedRoutes = () => {
    return token ? <Outlet /> : <Navigate to="/login" replace />;
  //  return <Outlet/>
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="games" element={<Game/>} />
            <Route path="win-settings" element={<WinSettings/>} />
            <Route path="house" element={<House />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="history" element={<History />} />
            <Route path="seo-manager" element={<SEO/>} />
            <Route path="affiliate" element={<Referal/>} />
            <Route path="bonus" element={<Bonus/>}/>
            <Route path="club" element={<ClubMemberShip/>}/>
            <Route path="siteconfig" element={<SiteConfig/>}/>
            <Route path="banner" element={<Banner/>}/>
            <Route path="bankdetails" element={<BankDetails/>}/>
            <Route path="redeemcode" element={<RedeemCode/>}/>
            <Route path="sportsbeting" element={<SportsBetting/>}/>
            <Route path="exchangerate" element={<ExchangeRate/>}/>
            <Route path="notification" element={<Notification/>}/>
            <Route path="swaphistory" element={<SwapHistory/>}/>
            <Route path="notification-settings" element={<NotificationSettings/>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
